import axios from "axios"
import moment from "moment"

export const SITE_URL = process.env.REACT_APP_API_URL

export const BASE_URL = SITE_URL + "/api"
export const UPLOADS_URL = SITE_URL + "/uploads"
export const CATEGORIES_UPLOADS_URL = UPLOADS_URL + "/categories/"
export const OCCASIONS_UPLOADS_URL = UPLOADS_URL + "/occasions/"
export const MEMBERS_UPLOADS_URL = UPLOADS_URL + "/members/"
export const SETTINGS_UPLOADS_URL = UPLOADS_URL + "/settings/"
export const POSTAL_CODE_URL =
  "https://yubinbango.github.io/yubinbango-data/data"
export const PUBLIC_HOLIDAY_URL =
  "https://holidays-jp.github.io/api/v1/date.json"

/* BACKEND API URLS */
export const API_ADMIN_CHECK_SESSION = BASE_URL + "/sess"
export const API_ADMIN_LOGIN = BASE_URL + "/login"
export const API_ADMIN_LOGOUT = BASE_URL + "/logout"
export const API_ADMIN_AUTH = BASE_URL + "/auth"
export const API_ADMIN_DAILY_STATS = BASE_URL + "/m/dailyStats"
export const API_ADMIN_OCCASIONS = BASE_URL + "/m/occasions"
export const API_ADMIN_OCCURRENCES = BASE_URL + "/m/occurrences"
export const API_ADMIN_CATEGORIES = BASE_URL + "/m/categories"
export const API_ADMIN_REGISTRATIONS = BASE_URL + "/m/registrations"
export const API_ADMIN_MEMBERS = BASE_URL + "/m/members"
export const API_ADMIN_CHATS = BASE_URL + "/m/members/chats"
export const API_ADMIN_SETTINGS = BASE_URL + "/m/settings"
export const API_ADMIN_RICHMENUS = BASE_URL + "/m/richmenus"
export const API_ADMIN_CAMPAIGNS = BASE_URL + "/m/campaigns"
export const API_ADMIN_AUDIENCES = BASE_URL + "/m/audiences"
export const API_ADMIN_SPECTATORS = BASE_URL + "/m/spectators"
export const API_CLIENT = BASE_URL + "/liff"

/* SOCKET PATH */
export const SOCKET_PATH = "/socket.io"

/* QUERY KEY */
export const QUERY_KEY_ADMIN_PUBLIC_SETTINGS = "ADMIN_PUBLIC_SETTINGS"
export const QUERY_KEY_ADMIN_CHECK_SESSION = "ADMIN_CHECK_SESSION"
export const QUERY_KEY_ADMIN_AUTH = "ADMIN_AUTH"
export const QUERY_KEY_ADMIN_DAILY_STATS = "ADMIN_DAILY_STATS"
export const QUERY_KEY_ADMIN_CATEGORY_TEMPLATES = "ADMIN_CATEGORY_TEMPLATES"
export const QUERY_KEY_ADMIN_CATEGORY_TEMPLATE_DETAIL =
  "ADMIN_CATEGORY_TEMPLATE_DETAIL"
export const QUERY_KEY_ADMIN_CATEGORY_TAGS = "ADMIN_CATEGORY_TAGS"
export const QUERY_KEY_ADMIN_CATEGORY_AREAS = "ADMIN_CATEGORY_AREAS"
export const QUERY_KEY_ADMIN_CATEGORIES = "ADMIN_CATEGORIES"
export const QUERY_KEY_ADMIN_CATEGORY_LIST = "ADMIN_CATEGORY_LIST"
export const QUERY_KEY_ADMIN_CATEGORY_DETAIL = "ADMIN_CATEGORY_DETAIL"
export const QUERY_KEY_ADMIN_OCCASIONS = "ADMIN_OCCASIONS"
export const QUERY_KEY_ADMIN_OCCASION_LIST = "ADMIN_OCCASION_LIST"
export const QUERY_KEY_ADMIN_OCCASION_DETAIL = "ADMIN_OCCASION_DETAIL"
export const QUERY_KEY_ADMIN_OCCASION_TAGS = "ADMIN_OCCASION_TAGS"
export const QUERY_KEY_ADMIN_OCCASION_TEMPLATES = "ADMIN_OCCASION_TEMPLATES"
export const QUERY_KEY_ADMIN_OCCASION_TEMPLATE_DETAIL =
  "OCCASION_TEMPLATE_DETAIL"
export const QUERY_KEY_ADMIN_OCCURRENCE_DETAIL = "ADMIN_OCCURRENCE_DETAIL"
export const QUERY_KEY_ADMIN_REGISTRATION_DETAIL = "ADMIN_REGISTRATION_DETAIL"
export const QUERY_KEY_ADMIN_MEMBERS = "ADMIN_MEMBERS"
export const QUERY_KEY_ADMIN_MEMBERS_LIST = "ADMIN_MEMBERS_LIST"
export const QUERY_KEY_ADMIN_MEMBER_DETAIL = "ADMIN_MEMBER_DETAIL"
export const QUERY_KEY_ADMIN_CHATS = "ADMIN_CHATS"
export const QUERY_KEY_ADMIN_RICHMENUS = "ADMIN_RICHMENUS"
export const QUERY_KEY_ADMIN_CAMPAIGN_QUESTIONS = "ADMIN_CAMPAIGN_QUESTIONS"
export const QUERY_KEY_ADMIN_AUDIENCES = "ADMIN_AUDIENCES"
export const QUERY_KEY_ADMIN_SPECTATORS = "ADMIN_SPECTATORS"
export const QUERY_KEY_ADMIN_SPECTATOR_CANDIDATES = "ADMIN_SPECTATOR_CANDIDATES"
export const QUERY_KEY_CLIENT_PERSONAL_INFO = "CLIENT_PERSONAL_INFO"
export const QUERY_KEY_CLIENT_CAMPAIGN_QUESTIONS = "CLIENT_CAMPAIGN_QUESTIONS"
export const QUERY_KEY_CLIENT_CATEGORIES = "CLIENT_CATEGORIES"
export const QUERY_KEY_CLIENT_CATEGORY_DETAIL = "CLIENT_CATEGORY_DETAIL"
export const QUERY_KEY_CLIENT_OCCASIONS = "CLIENT_OCCASIONS"
export const QUERY_KEY_CLIENT_OCCASION_DETAIL = "CLIENT_OCCASION_DETAIL"
export const QUERY_KEY_CLIENT_MY_REGISTRATIONS = "CLIENT_MY_REGISTRATIONS"
export const QUERY_KEY_CLIENT_MY_REGISTRATION_DETAIL =
  "CLIENT_MY_REGISTRATION_DETAIL"
export const QUERY_KEY_LOGO = "LOGO"
export const QUERY_KEY_FAVICON = "FAVICON"
export const QUERY_KEY_STORE_PIC = "STORE_PIC"
export const QUERY_KEY_PUBLIC_SETTINGS = "PUBLIC_SETTINGS"
export const QUERY_KEY_PUBLIC_HOLIDAYS = "PUBLIC_HOLIDAYS"
export const QUERY_KEY_ORDER_HISTORY = "MEMBER_ORDER_HISTORY"

/* SETTINGS KEY */
export const SETTINGS_KEY_SYSTEM_COLOR = "systemColor"
export const SETTINGS_KEY_SYSTEM_TITLE = "systemTitle"
export const SETTINGS_KEY_CLIENT_CATEGORIES_SCREEN_TITLE =
  "clientCategoriesScreenTitle"
export const SETTINGS_KEY_CLIENT_CATEGORIES_SCREEN_SUBTITLE =
  "clientCategoriesScreenSubtitle"
export const SETTINGS_KEY_ADMIN_INITIAL_RESERVABLE_COUNT =
  "adminInitialReservableCount"
export const SETTINGS_KEY_RESERVATION_CONFIRM_URL = "bookConfirmUrl"
export const SETTINGS_KEY_COMPANY_TELEPHONE = "companyTelephone"
export const SETTINGS_KEY_BOOK_LIMIT_ENABLED = "bookLimitEnabled"
export const SETTINGS_KEY_BOOK_LIMIT_DAY = "bookLimitDay"
export const SETTINGS_KEY_BOOK_LIMIT_HOUR = "bookLimitHour"
export const SETTINGS_KEY_BOOK_LIMIT_MINUTE = "bookLimitMinute"
export const SETTINGS_KEY_BOOK_CANCEL_ALLOWED = "bookCancelAllowed"
export const SETTINGS_KEY_BOOK_CANCEL_LIMIT_DAY = "bookCancelLimitDay"
export const SETTINGS_KEY_BOOK_CANCEL_LIMIT_HOUR = "bookCancelLimitHour"
export const SETTINGS_KEY_BOOK_CANCEL_LIMIT_MINUTE = "bookCancelLimitMinute"
export const SETTINGS_KEY_BOOK_CANCEL_TEXT = "bookCancelText"
export const SETTINGS_KEY_MEMBER_MESSAGE_RESERVATION = "bookRegistrationMessage"
export const SETTINGS_KEY_MEMBER_MESSAGE_CAMPAIGN = "campaignApplyMessage"
export const SETTINGS_KEY_MEMBER_MESSAGE_REMIND1 = "bookRemind1Message"
export const SETTINGS_KEY_MEMBER_MESSAGE_REMIND2 = "bookRemind2Message"
export const SETTINGS_KEY_ADMIN_MESSAGE_MEMBER = "watchMemberTemplate"
export const SETTINGS_KEY_ADMIN_MESSAGE_CAMPAIGN = "watchCampaignApplyTemplate"
export const SETTINGS_KEY_ADMIN_MESSAGE_RESERVATION =
  "watchRegistrationTemplate"
export const SETTINGS_KEY_ADMIN_MESSAGE_RESERVATION_CANCEL =
  "watchRegistrationCancelTemplate"

/* SETTINGS LABEL */
export const SETTINGS_LABEL_SYSTEM_COLOR = "システム色"
export const SETTINGS_LABEL_SYSTEM_TITLE = "システムタイトル"
export const SETTINGS_LABEL_CLIENT_CATEGORIES_SCREEN_TITLE = "予約画面タイトル"
export const SETTINGS_LABEL_CLIENT_CATEGORIES_SCREEN_SUBTITLE =
  "予約画面サブタイトル"
export const SETTINGS_LABEL_ADMIN_INITIAL_RESERVABLE_COUNT =
  "予約可能人数の初期値"
export const SETTINGS_LABEL_RESERVATION_CONFIRM_URL = "予約確認URL"
export const SETTINGS_LABEL_COMPANY_TELEPHONE = "問い合わせ電話番号"
export const SETTINGS_LABEL_BOOK_LIMIT_ENABLED = "予約限定"
export const SETTINGS_LABEL_BOOK_LIMIT_DAY = "予約限定日数"
export const SETTINGS_LABEL_BOOK_LIMIT_HOUR = "予約限定時"
export const SETTINGS_LABEL_BOOK_LIMIT_MINUTE = "予約限定分"
export const SETTINGS_LABEL_BOOK_CANCEL_ALLOWED = "予約キャンセル可能"
export const SETTINGS_LABEL_BOOK_CANCEL_LIMIT_DAY = "予約キャンセル限定日"
export const SETTINGS_LABEL_BOOK_CANCEL_LIMIT_HOUR = "予約キャンセル限定時"
export const SETTINGS_LABEL_BOOK_CANCEL_LIMIT_MINUTE = "予約キャンセル限定分"
export const SETTINGS_LABEL_BOOK_CANCEL_TEXT = "予約キャンセル説明文"
export const SETTINGS_LABEL_MEMBER_MESSAGE_RESERVATION = "予約メッセージ"
export const SETTINGS_LABEL_MEMBER_MESSAGE_CAMPAIGN =
  "キャンペーン応募メッセージ"
export const SETTINGS_LABEL_MEMBER_MESSAGE_REMIND1 = "予約リマインドメッセージ1"
export const SETTINGS_LABEL_MEMBER_MESSAGE_REMIND2 = "予約リマインドメッセージ2"
export const SETTINGS_LABEL_ADMIN_MESSAGE_MEMBER = "新規メンバーメッセージ"
export const SETTINGS_LABEL_ADMIN_MESSAGE_CAMPAIGN =
  "新規キャンペーン応募メッセージ"
export const SETTINGS_LABEL_ADMIN_MESSAGE_RESERVATION = "新規予約メッセージ"
export const SETTINGS_LABEL_ADMIN_MESSAGE_RESERVATION_CANCEL =
  "予約キャンセルメッセージ"

/* SOCKET LABEL */
export const SOCKET_SYSTEM_SETTING = "systemSetting"
export const SOCKET_FAVICON = "favicon"
export const SOCKET_LOGO = "logo"
export const SOCKET_STORE_PIC = "storePic"
export const SOCKET_CATEGORY = "category"
export const SOCKET_TEMPLATE = "template"
export const SOCKET_OCCASION = "occasion"
export const SOCKET_OCCURRENCE = "occurrence"
export const SOCKET_REGISTRATION = "registration"
export const SOCKET_MEMBER = "member"
export const SOCKET_CHAT = "chat"
export const SOCKET_AUDIENCE = "audience"

/* AXIOS CONFIG */
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  timeout: 20000,
})

/* BACKEND API REQUESTS */
export async function ADMIN_LOGIN(data) {
  return await axiosInstance.post(API_ADMIN_LOGIN, data)
}

export async function ADMIN_LOGOUT() {
  return await axiosInstance.get(API_ADMIN_LOGOUT)
}

export async function ADMIN_CHECK_SESSION() {
  return await axiosInstance.get(API_ADMIN_CHECK_SESSION)
}

export async function ADMIN_GET_AUTH() {
  return await axiosInstance.get(API_ADMIN_AUTH)
}

export async function ADMIN_GET_DAILY_STATS(filterStatsFrom, filterStatsTo) {
  const paramData = {
    params: {
      from: filterStatsFrom,
      to: filterStatsTo,
    },
  }

  return await axiosInstance.get(API_ADMIN_DAILY_STATS, paramData)
}

export async function ADMIN_GET_CATEGORY_TEMPLATES() {
  const paramData = {
    params: {
      includeDestroyed: false,
    },
  }

  return await axiosInstance.get(API_ADMIN_CATEGORIES + "/basic", paramData)
}

export async function ADMIN_GET_CATEGORY_TEMPLATE_DETAIL(categoryId) {
  const paramData = {
    params: {
      includeDestroyed: false,
    },
  }

  return await axiosInstance.get(
    API_ADMIN_CATEGORIES + "/" + categoryId,
    paramData
  )
}

export async function ADMIN_GET_CATEGORY_TAGS() {
  return await axiosInstance.get(API_ADMIN_CATEGORIES + "/tags")
}

export async function ADMIN_GET_CATEGORY_AREAS() {
  return await axiosInstance.get(API_ADMIN_CATEGORIES + "/areas")
}

export async function ADMIN_GET_CATEGORIES({ pageParam = 1, queryKey }) {
  const paramData = {
    params: {
      pp: 20,
      p: pageParam,
    },
  }

  return await axiosInstance.get(API_ADMIN_CATEGORIES, paramData)
}

export async function ADMIN_GET_CATEGORY_LIST(includePic, includeDestroyed) {
  const paramData = {
    params: {
      includePic: includePic,
      includeDestroyed: includeDestroyed,
    },
  }

  return await axiosInstance.get(API_ADMIN_CATEGORIES + "/basic", paramData)
}

export async function ADMIN_GET_CATEGORY_DETAIL(
  categoryId,
  filterOccurrencesFrom,
  filterOccurrencesTo
) {
  const paramData = {
    params: {
      from: filterOccurrencesFrom,
      to: filterOccurrencesTo,
    },
  }

  return await axiosInstance.get(
    API_ADMIN_CATEGORIES + "/" + categoryId,
    paramData
  )
}

export async function ADMIN_CREATE_CATEGORY(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  return await axiosInstance.post(
    API_ADMIN_CATEGORIES,
    data?.formData,
    headerData
  )
}

export async function ADMIN_UPDATE_CATEGORY(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  return await axiosInstance.put(
    API_ADMIN_CATEGORIES + "/" + data?.categoryId,
    data?.formData,
    headerData
  )
}

export async function ADMIN_UPDATE_CATEGORY_ORDER(data) {
  return await axiosInstance.put(API_ADMIN_CATEGORIES, data)
}

export async function ADMIN_DELETE_CATEGORY(data) {
  return await axiosInstance.delete(
    API_ADMIN_CATEGORIES + "/" + data?.categoryId
  )
}

export async function ADMIN_GET_OCCASION_TEMPLATES() {
  const paramData = {
    params: {
      includeDestroyed: false,
    },
  }

  return await axiosInstance.get(API_ADMIN_OCCASIONS + "/basic", paramData)
}

export async function ADMIN_GET_OCCASION_TEMPLATE_DETAIL(occasionId) {
  const paramData = {
    params: {
      includeDestroyed: false,
    },
  }

  return await axiosInstance.get(
    API_ADMIN_OCCASIONS + "/" + occasionId,
    paramData
  )
}

export async function ADMIN_GET_OCCASIONS({ pageParam = 1, queryKey }) {
  const paramData = {
    params: {
      categoryId: queryKey[1],
      pp: 20,
      p: pageParam,
    },
  }

  return await axiosInstance.get(API_ADMIN_OCCASIONS, paramData)
}

export async function ADMIN_GET_OCCASION_LIST(includePic, includeDestroyed) {
  const paramData = {
    params: {
      includePic: includePic,
      includeDestroyed: includeDestroyed,
    },
  }

  return await axiosInstance.get(API_ADMIN_OCCASIONS + "/basic", paramData)
}

export async function ADMIN_GET_OCCASION_DETAIL(
  occasionId,
  filterOccurrencesFrom,
  filterOccurrencesTo
) {
  const paramData = {
    params: {
      from: filterOccurrencesFrom,
      to: filterOccurrencesTo,
    },
  }

  return await axiosInstance.get(
    API_ADMIN_OCCASIONS + "/" + occasionId,
    paramData
  )
}

export async function ADMIN_CREATE_OCCASION(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  return await axiosInstance.post(
    API_ADMIN_OCCASIONS,
    data?.formData,
    headerData
  )
}

export async function ADMIN_UPDATE_OCCASION(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  return await axiosInstance.put(
    API_ADMIN_OCCASIONS + "/" + data?.occasionId,
    data?.formData,
    headerData
  )
}

export async function ADMIN_UPDATE_OCCASION_ORDER(data) {
  return await axiosInstance.put(API_ADMIN_OCCASIONS, data)
}

export async function ADMIN_DELETE_OCCASION(data) {
  return await axiosInstance.delete(
    API_ADMIN_OCCASIONS + "/" + data?.occasionId
  )
}

export async function ADMIN_GET_OCCASION_TAGS() {
  return await axiosInstance.get(API_ADMIN_OCCASIONS + "/tags")
}

export async function ADMIN_CREATE_OCCURRENCE(data) {
  return await axiosInstance.post(API_ADMIN_OCCURRENCES, data)
}

export async function ADMIN_GET_OCCURRENCE_DETAIL(occurrenceId) {
  return await axiosInstance.get(API_ADMIN_OCCURRENCES + "/" + occurrenceId)
}

export async function ADMIN_UPDATE_OCCURRENCE(data) {
  return await axiosInstance.put(
    API_ADMIN_OCCURRENCES + "/" + data?.occurrenceId,
    data
  )
}

export async function ADMIN_DELETE_OCCURRENCE(data) {
  return await axiosInstance.delete(
    API_ADMIN_OCCURRENCES + "/" + data?.occurrenceId
  )
}

export async function ADMIN_DELETE_BATCH_OCCURRENCES(data) {
  return await axiosInstance.delete(API_ADMIN_OCCURRENCES, { data: data })
}

export async function ADMIN_CREATE_REGISTRATION_MANUAL(data) {
  return await axiosInstance.post(API_ADMIN_REGISTRATIONS, data)
}

export async function ADMIN_UPDATE_REGISTRATION(data) {
  return await axiosInstance.put(API_ADMIN_REGISTRATIONS + "/edit", data)
}

export async function ADMIN_CANCEL_REGISTRATION(data) {
  const param = {
    data: {},
  }

  return await axiosInstance.delete(
    API_ADMIN_REGISTRATIONS + "/" + data.registrationId + "/cancel",
    param
  )
}

export async function ADMIN_CONFIRM_REGISTRATION(data) {
  return await axiosInstance.post(API_ADMIN_REGISTRATIONS + "/qr", data)
}

export async function ADMIN_RECONFIRM_REGISTRATION(data) {
  return await axiosInstance.put(API_ADMIN_REGISTRATIONS + "/confirm", data)
}

export async function ADMIN_EXPORT_REGISTRATIONS(data) {
  return await axiosInstance.post(API_ADMIN_REGISTRATIONS + "/csv", data)
}

export async function ADMIN_GET_MEMBERS(
  paginationPerPage,
  paginationPage,
  paginationSort,
  paginationSortKey,
  filterName,
  filterMemberCode,
  filterTelephone,
  filterEmail,
  filterAddress,
  filterMemberSinceMax,
  filterMemberSinceMin,
  filterIsRegistered,
  filterMemberPosId,
  prefecture,
  birthMonth,
  gender,
  membershipCategory,
  notifyMobile,
  blackCategory,
  notifyPC,
  notifyDM,
  pointMin,
  pointMax,
  isLinkedEc,
  registerDateMin,
  registerDateMax,
  purchaseCountMin,
  purchaseCountMax,
  purchaseTotalMin,
  purchaseTotalMax,
  pointExpirationDateMin,
  pointExpirationDateMax,
  rank,
  purchaseLatestAt,
  productId,
  manufacturerCode,
  code,
  storeCode,
  zoneCode,
  soldDateMin,
  soldDateMax
) {
  const paramData = {
    params: {
      pp: paginationPerPage,
      p: paginationPage,
      sort: paginationSort,
      sortKey: paginationSortKey,
      name: filterName,
      memberId: filterMemberCode,
      memberPosId: filterMemberPosId,
      telephone: filterTelephone,
      email: filterEmail,
      address: filterAddress,
      memberSinceMin: filterMemberSinceMin,
      memberSinceMinMax: filterMemberSinceMax,
      isRegistered: filterIsRegistered,
      prefecture: prefecture,
      birthMonth: birthMonth,
      gender: gender,
      membershipCategory: membershipCategory,
      notifyMobile: notifyMobile,
      blackCategory: blackCategory,
      notifyPC: notifyPC,
      notifyDM: notifyDM,
      pointMin: pointMin,
      pointMax: pointMax,
      isLinkedEc: isLinkedEc,
      registerDateMin: registerDateMin,
      registerDateMax: registerDateMax,
      purchaseCountMin: purchaseCountMin,
      purchaseCountMax: purchaseCountMax,
      purchaseTotalMin: purchaseTotalMin,
      purchaseTotalMax: purchaseTotalMax,
      pointExpirationDateMin: pointExpirationDateMin,
      pointExpirationDateMax: pointExpirationDateMax,
      rank: rank,
      purchaseLatestAt: purchaseLatestAt,
      productId: productId,
      manufacturerCode: manufacturerCode,
      code: code,
      storeCode: storeCode,
      zoneCode: zoneCode,
      soldDateMin: soldDateMin,
      soldDateMax: soldDateMax,
    },
  }

  return await axiosInstance.get(API_ADMIN_MEMBERS, paramData)
}

export async function ADMIN_GET_MEMBER(data) {
  return await axiosInstance.get(API_ADMIN_MEMBERS + "/" + data.memberId)
}
export async function ADMIN_GET_MEMBER_ORDER_HISTORY(data) {
  return await axiosInstance.get(
    API_ADMIN_MEMBERS + "/" + data.memberId + "/purchase/history"
  )
}

export async function ADMIN_DELETE_MEMBER(data) {
  return await axiosInstance.delete(API_ADMIN_MEMBERS + "/" + data.memberId)
}
export async function ADMIN_DISCONNECT_LINE_MEMBER(data) {
  return await axiosInstance.delete(API_ADMIN_MEMBERS + "/" + data.memberId +"/line")
}

export async function ADMIN_RENEW_MEMBER(data) {
  return await axiosInstance.put(API_ADMIN_MEMBERS + "/" + data.memberId, data)
}

export async function ADMIN_CHECK_MEMBER(data) {
  return await axiosInstance.post(API_ADMIN_MEMBERS + "/barcode", data)
}

export async function ADMIN_EXPORT_MEMBERS(data) {
  return await axiosInstance.post(API_ADMIN_MEMBERS + "/csv", data)
}

export async function ADMIN_EXPORT_MEMBERS_CSV(data) {
	const paramData = {
		params: {
      sort: data.paginationSort,
      sortKey: data.paginationSortKey,
      name: data.filterName,
      memberId: data.filterMemberCode,
      memberPosId: data.filterMemberPosId,
      telephone: data.filterTelephone,
      email: data.filterEmail,
      address: data.filterAddress,
      memberSinceMin: data.filterMemberSinceMin,
      memberSinceMinMax: data.filterMemberSinceMax,
      isRegistered: data.filterIsRegistered,
      prefecture: data.prefecture,
      birthMonth: data.birthMonth,
      gender: data.gender,
      membershipCategory: data.membershipCategory,
      notifyMobile: data.notifyMobile,
      blackCategory: data.blackCategory,
      notifyPC: data.notifyPC,
      notifyDM: data.notifyDM,
      pointMin: data.pointMin,
      pointMax: data.pointMax,
      isLinkedEc: data.isLinkedEc,
      registerDateMin: data.registerDateMin,
      registerDateMax: data.registerDateMax,
      purchaseCountMin: data.purchaseCountMin,
      purchaseCountMax: data.purchaseCountMax,
      purchaseTotalMin: data.purchaseTotalMin,
      purchaseTotalMax: data.purchaseTotalMax,
      pointExpirationDateMin: data.pointExpirationDateMin,
      pointExpirationDateMax: data.pointExpirationDateMax,
      rank: data.rank,
      purchaseLatestAt: data.purchaseLatestAt,
      productId: data.productId,
      manufacturerCode: data.manufacturerCode,
      code: data.code,
      storeCode: data.storeCode,
      zoneCode: data.zoneCode,
      soldDateMin: data.soldDateMin,
      soldDateMax: data.soldDateMax,
    },
    responseType: 'blob'
	}

  const clonedAxiosInstance = axios.create({
    ...axiosInstance.defaults,
    timeout: 900000 // 15 minutes
  })

	return await clonedAxiosInstance.get(API_ADMIN_MEMBERS + "/export/csv", paramData)
}

export async function ADMIN_EXPORT_AUDIENCE_CSV(data) {
  const paramData = {
		params: {
      audienceGroupId: data.audienceGroupId
    },
    responseType: 'blob'
  }

  const clonedAxiosInstance = axios.create({
    ...axiosInstance.defaults,
    timeout: 900000 // 15 minutes
  })

	return await clonedAxiosInstance.get(API_ADMIN_AUDIENCES + "/export/csv", paramData)
}

export async function ADMIN_GET_CHATS(member) {
  return await axiosInstance.get(API_ADMIN_CHATS + "/" + member?.memberId)
}

export async function ADMIN_SEND_CHAT(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData();
  for (const file of data.fileList) {
    formData.append('photos[]', file)
  }
  formData.append('contents', data.contents)

  return await axiosInstance.post(API_ADMIN_CHATS + "/" + data.memberId, formData, headerData )
}

export async function ADMIN_CREATE_CAMPAIGN_QUESTION(data) {
  return await axiosInstance.post(API_ADMIN_CAMPAIGNS + "/questions", data)
}

export async function ADMIN_UPDATE_CAMPAIGN_QUESTION(data) {
  return await axiosInstance.put(
    API_ADMIN_CAMPAIGNS + "/questions/" + data?.questionId,
    data
  )
}

export async function ADMIN_UPDATE_CAMPAIGN_QUESTION_ORDER(data) {
  return await axiosInstance.put(API_ADMIN_CAMPAIGNS + "/questions", data)
}

export async function ADMIN_DELETE_CAMPAIGN_QUESTION(data) {
  return await axiosInstance.delete(
    API_ADMIN_CAMPAIGNS + "/questions/" + data?.questionId
  )
}

export async function ADMIN_GET_CAMPAIGN_CANDIDATES(data) {
  return await axiosInstance.get(API_ADMIN_CAMPAIGNS + "/candidates", data)
}
export async function ADMIN_GET_CAMPAIGN_QUESTIONS() {
  const paramData = {
    params: {},
  }

  return await axiosInstance.get(API_ADMIN_CAMPAIGNS + "/questions", paramData)
}

export async function ADMIN_UPDATE_CAMPAIGN_CANDIDATE(data) {
  return await axiosInstance.post(API_ADMIN_CAMPAIGNS + "/winners", data)
}

export async function ADMIN_EXPORT_CAMPAIGN_WINNERS(data) {
  return await axiosInstance.post(API_ADMIN_CAMPAIGNS + "/winners/csv", data)
}

export async function ADMIN_RESET_CAMPAIGN_WINNERS(data) {
  return await axiosInstance.post(API_ADMIN_CAMPAIGNS + "/winners/reset", data)
}

export async function ADMIN_GET_AUDIENCES() {
  return await axiosInstance.get(API_ADMIN_AUDIENCES)
}

export async function ADMIN_CREATE_AUDIENCE(data) {
  return await axiosInstance.post(API_ADMIN_AUDIENCES, data)
}

export async function ADMIN_DELETE_AUDIENCE(data) {
  return await axiosInstance.delete(
    API_ADMIN_AUDIENCES + "/" + data.audienceGroupId
  )
}

export async function ADMIN_SEARCH_AUDIENCE_MEMBERS(data) {
  return await axiosInstance.post(API_ADMIN_AUDIENCES + "/find", data)
}

export async function ADMIN_GET_SPECTATORS() {
  const paramData = {
    params: {},
  }

  return await axiosInstance.get(API_ADMIN_SPECTATORS, paramData)
}

export async function ADMIN_GET_SPECTATOR_CANDIDATES() {
  const paramData = {
    params: {},
  }

  return await axiosInstance.get(
    API_ADMIN_SPECTATORS + "/candidates",
    paramData
  )
}

export async function ADMIN_REMOVE_SPECTATOR(data) {
  return await axiosInstance.delete(
    API_ADMIN_SPECTATORS + "/" + data.spectatorId
  )
}

export async function ADMIN_ADD_SPECTATOR(data) {
  return await axiosInstance.post(API_ADMIN_SPECTATORS, data)
}

export async function GET_LOGO() {
  return await axiosInstance.get(BASE_URL + "/logo")
}

export async function ADMIN_UPLOAD_LOGO(logo) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  formData.append("picUrl", logo)

  return await axiosInstance.put(BASE_URL + "/m/logo", formData, headerData)
}

export async function GET_FAVICON() {
  return await axiosInstance.get(BASE_URL + "/favicon")
}

export async function ADMIN_UPLOAD_FAVICON(favicon) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  formData.append("picUrl", favicon)

  return await axiosInstance.put(BASE_URL + "/m/favicon", formData, headerData)
}

export async function GET_STORE_PIC() {
  return await axiosInstance.get(BASE_URL + "/store/pic")
}

export async function ADMIN_UPLOAD_STORE_PIC(logo) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  formData.append("picUrl", logo)

  return await axiosInstance.put(
    BASE_URL + "/m/store/pic",
    formData,
    headerData
  )
}

export async function GET_ADMIN_PUBLIC_SETTINGS() {
  return await axiosInstance.get(BASE_URL + "/m/settings")
}

export async function GET_PUBLIC_SETTINGS() {
  return await axiosInstance.get(BASE_URL + "/settings")
}

export async function ADMIN_UPDATE_SETTINGS_BATCH(data) {
  return await axiosInstance.put(API_ADMIN_SETTINGS, data)
}

export async function ADMIN_UPDATE_PUBLIC_SETTINGS(data) {
  return await axiosInstance.put(API_ADMIN_SETTINGS + "/" + data.key, data)
}

export async function ADMIN_GET_RICHMENUS() {
  return await axiosInstance.get(API_ADMIN_RICHMENUS)
}

export async function ADMIN_UPDATE_RICHMENU(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })

  return await axiosInstance.put(API_ADMIN_RICHMENUS, formData, headerData)
}

export async function ADMIN_UPDATE_RICHMENU_VISIBILITY(data) {
  return await axiosInstance.put(API_ADMIN_RICHMENUS + "/props", data)
}

export async function ADMIN_DELETE_RICHMENU(data) {
  const param = {
    data: {
      type: data.type,
    },
  }

  return await axiosInstance.delete(API_ADMIN_RICHMENUS, param)
}

export async function CLIENT_GET_PERSONAL_INFO(accessToken) {
  const headerData = { headers: { "access-token": accessToken } }

  return await axiosInstance.get(API_CLIENT + "/personal", headerData)
}
export async function CLIENT_GET_ORDER_HISTORY(accessToken) {
  const headerData = { headers: { "access-token": accessToken } }

  return await axiosInstance.get(API_CLIENT + "/purchase/history", headerData)
}

export async function CLIENT_LOGIN_EC(data) {
  const headerData = {
    headers: { "access-token": data.accessToken },
  }
  return await axiosInstance.post(API_CLIENT + "/eclogin", data, headerData)
}

export async function CLIENT_REGISTER_PROFILE(data) {
  const headerData = {
    headers: { "access-token": data.accessToken },
  }

  return await axiosInstance.post(API_CLIENT + "/personal", data, headerData)
}

export async function CLIENT_POST_MEMBER_CHECK(data) {
  const headerData = {
    headers: { "access-token": data.accessToken },
  }

  return await axiosInstance.post(API_CLIENT + "/posSync", data, headerData)
}
export async function CLIENT_EC_MEMBER_CHECK(data) {
  const headerData = {
    headers: { "access-token": data.accessToken },
  }

  return await axiosInstance.post(API_CLIENT + "/ecSync", data, headerData)
}

export async function CLIENT_UPDATE_PROFILE(data) {
  const headerData = {
    headers: {
      "access-token": data.accessToken,
    },
  }

  return await axiosInstance.put(API_CLIENT + "/personal", data, headerData)
}

export async function CLIENT_CREATE_PROFILE(data) {
  
  return await axiosInstance.post(API_ADMIN_MEMBERS, data)
}

export async function CLIENT_GET_CAMPAIGN_QUESTIONS(accessToken) {
  const headerData = {
    headers: { "access-token": accessToken },
  }

  return await axiosInstance.get(
    API_CLIENT + "/campaigns/questions",
    headerData
  )
}

export async function CLIENT_APPLY_CAMPAIGN(data) {
  const headerData = {
    headers: {
      "access-token": data.accessToken,
    },
  }

  return await axiosInstance.post(
    API_CLIENT + "/campaigns/answers",
    data,
    headerData
  )
}

export async function CLIENT_GET_CATEGORIES({ pageParam = 1, queryKey }) {
  const headerData = {
    headers: { "access-token": queryKey[1] },
    params: {
      pp: 20,
      p: pageParam,
    },
  }

  return await axiosInstance.get(API_CLIENT + "/categories", headerData)
}

export async function CLIENT_GET_CATEGORY_DETAIL(accessToken, categoryId) {
  const paramData = {
    headers: { "access-token": accessToken },
    params: {
      from: moment().format("YYYY-MM-DD HH:mm"),
      to: moment()
        .add(1, "month")
        .endOf("month")
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
    },
  }

  return await axiosInstance.get(
    API_CLIENT + "/categories/" + categoryId,
    paramData
  )
}

export async function CLIENT_GET_OCCASIONS({ pageParam = 1, queryKey }) {
  const headerData = {
    headers: { "access-token": queryKey[1] },
    params: {
      pp: 20,
      p: pageParam,
      categoryId: queryKey[2],
    },
  }

  return await axiosInstance.get(API_CLIENT + "/occasions", headerData)
}

export async function CLIENT_GET_OCCASION_DETAIL(accessToken, occasionId) {
  const paramData = {
    headers: { "access-token": accessToken },
    params: {
      from: moment().format("YYYY-MM-DD HH:mm"),
      to: moment()
        .add(1, "month")
        .endOf("month")
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
    },
  }

  return await axiosInstance.get(
    API_CLIENT + "/occasions/" + occasionId,
    paramData
  )
}

export async function CLIENT_RESERVE_OCCASION(data) {
  const headerData = {
    headers: {
      "access-token": data.accessToken,
    },
  }

  return await axiosInstance.post(
    API_CLIENT + "/registerForEvent",
    data,
    headerData
  )
}

export async function CLIENT_GET_MY_REGISTRATIONS(accessToken) {
  const headerData = {
    headers: { "access-token": accessToken },
  }

  return await axiosInstance.get(API_CLIENT + "/registrations", headerData)
}

export async function CLIENT_GET_MY_REGISTRATION_DETAIL(
  accessToken,
  registrationId
) {
  const headerData = {
    headers: { "access-token": accessToken },
  }

  return await axiosInstance.get(
    API_CLIENT + "/registrations/" + registrationId,
    headerData
  )
}

export async function CLIENT_CANCEL_REGISTRATION(data) {
  const param = {
    headers: { "access-token": data.accessToken },
    data: {},
  }

  return await axiosInstance.delete(
    API_CLIENT + "/registrations/" + data.registrationId,
    param
  )
}

export const GET_PUBLIC_HOLIDAYS = async () => {
  return await axios.get(PUBLIC_HOLIDAY_URL)
}

export const GET_ADDRESS_BY_POSTAL_CODE = (postalCode) => {
  const p3 = postalCode.substr(0, 3)

  return fetch(`${POSTAL_CODE_URL}/${p3}.js`)
    .then((response) => response.text())
    .then((text) => text)
    .catch((error) => {})
}
